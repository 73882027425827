.monthly-timesheet-time {
  height: initial;
  width: 100%;
  margin: 1px 2px;
  padding: 0;
  background-color: transparent;
}

.rbc-ellipsis,
.rbc-event-label,
.rbc-row-segment .rbc-event-content,
.rbc-show-more {
  overflow: visible;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rbc-show-more {
  color: #2266ef;
}

.rbc-btn-group {
  padding: 2px;
  border: 1px solid rgb(228, 231, 235);
  border-radius: 6px;
  background-color: #fff;
}

.rbc-toolbar button {
  border: 1px solid #fff;
  color: #1f2939;
  font-size: 13px;
  font-weight: 600;
  border-radius: 6px;
  margin: 2px 4px;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  box-shadow: none;
  background-color: #2266ef;
  border-color: #2266ef;
  border-radius: 6px;
  color: #fff;
}

.rbc-toolbar button:active:hover,
.rbc-toolbar button.rbc-active:hover {
  border-color: #2266ef;
  background-color: #2266ef;
  color: #fff;
}

.rbc-toolbar button:hover {
  color: #1f2939;
  background-color: #fff;
  border-color: #2266ef;
}

.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:focus {
  color: #fff;
  background-color: #2266ef;
  border-color: #2266ef;
}

.rbc-btn-group > button:first-child:not(:last-child) {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.rbc-btn-group > button:not(:first-child):not(:last-child) {
  border-radius: 6px;
}
.rbc-btn-group > button:last-child:not(:first-child) {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.schedule-calendar .rbc-day-bg {
  cursor: pointer;
}

.schedule-calendar .rbc-day-bg:hover {
  cursor: pointer;
  background-color: #eaf0f5;
}

.rbc-day-slot .rbc-event-content {
  line-height: 1.3;
}

@media only screen and (max-width: 1480px) {
  .monthly-timesheet-time {
    height: initial;
    width: 100%;
    margin: 1px 2px !important;
  }
}
