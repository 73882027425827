.DateRangePickerInput {
    height: 40px;
    width: 100%;
    display: flex;
    border: 1px solid #E4E7EB;
    border-radius: 6px;
  }

.DateRangePickerInput__disabled {
  background: #fff;
  opacity: 0.5;
  border: none;
  cursor: not-allowed;
}

.DateInput__disabled {
  background: #fff;
  opacity: 0.5;
  border: none;
  cursor: not-allowed;
}

.DateInput_input__disabled {
  background: #fff;
  font-style: normal;
  cursor: not-allowed;
}

/* .DateInput_input__disabled {
  background-color: #fff;
  opacity: 0.5;
} */